import {environment} from 'src/environments/environment';
import {UserModel} from './shared/models/user/user.model';

export const routes = {
  auth: {
    login: () => `${environment.apiUrl}/login`,
    otp: () => `${environment.apiUrl}/login/otp`,
    logout: () => `${environment.apiUrl}/logout`,
    register: () => `${environment.apiUrl}/register`,
    verifyAccount: () => `${environment.apiUrl}/verify`,
    passwordRecovery: () => `${environment.apiUrl}/password-recovery`,
  },
  tabs: {
    tabs: () => `${environment.apiUrl}/tabs/get`,
  },
  session: {
    me: () => `${environment.apiUrl}/me`,
  },
  dashboard: {
    practice: () => `${environment.apiUrl}/counters/pratiche`,
    users: () => `${environment.apiUrl}/counters/users`,
    downloadReportExcel: () => `${environment.apiUrl}/exports/pratiche-by-user`
  },
  profile: {
    update: () => `${environment.apiUrl}/user`,
    signature: {
      set: () => `${environment.apiUrl}/user/sigle-media/signature`, //POST
      get: () => `${environment.apiUrl}/user/sigle-media/signature`, //GET
    },
    profileImage: {
      set: () => `${environment.apiUrl}/user/sigle-media/profile-image`, //POST
      get: () => `${environment.apiUrl}/user/sigle-media/profile-image`, //GET
    },
  },
  users: {
    list: (page: number) => `${environment.apiUrl}/users-management?page=${page}`,
    listByRole: (roleid: number | string | null, page: number) => `${environment.apiUrl}/users-management/by-role-id/${roleid}?page=${page}`,
    create: () => `${environment.apiUrl}/users-management`,
    show: (userid: UserModel['id']) => `${environment.apiUrl}/users-management/${userid}`,
    update: (userid: UserModel['id']) => `${environment.apiUrl}/users-management/${userid}`,
    delete: (userid: UserModel['id']) => `${environment.apiUrl}/users-management/${userid}`,
    search: (keyword: string, page: string, perPage?: string) => `${environment.apiUrl}/users-management/search/${keyword}/${perPage}?page=${page}`,
    searchByRole: (roleid: number | string | null, keyword: string, page: string, perPage?: string) => `${environment.apiUrl}/users-management/search/${keyword}/by-role-id/${roleid}/${perPage}?page=${page}`,
    searchForPraticaSetUser: (roleid: number | string | null, keyword: string, page: string, perPage?: string) => `${environment.apiUrl}/users-management/search-for-pratica-set-user/${keyword}/${roleid}/${perPage}?page=${page}`,
    downloadReportExcel: (userid: string) => `${environment.apiUrl}/exports/pratiche-by-user/${userid}`
  },
  practice: {
    list: (page: number) => `${environment.apiUrl}/pratiche/list?page=${page}`, //GET
    listBySottotipologia: (tipo: string | null, page: number) => `${environment.apiUrl}/pratiche/list/${tipo}?page=${page}`, //GET
    search: (page: string, perPage?: string) => `${environment.apiUrl}/pratiche/search/${perPage}?page=${page}`, //POST
    searchByType: (page: string, perPage?: string) => `${environment.apiUrl}/pratiche/search/${perPage}?page=${page}`, //GET
    create: () => `${environment.apiUrl}/pratiche`, //POST
    show: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}`,
    delete: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}`, //DELETE
    requestCheck: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/request-check-startup-docs`, //POST
    check: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/check-startup-docs`, //POST
    setMld: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/set-mld`, //POST
    setMla: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/set-mlo`, //POST
    updateUser: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/update-user-m`, //PUT
    storeContract: (practiceId: number, type: string) => `${environment.apiUrl}/pratiche/${practiceId}/panda/create-draft/${type}`, //POST
    getContract: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/panda/status`, //GET
    sendContract: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/panda/send`, //POST
    downloadContract: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/panda/get`, //POST
    deleteContract: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/panda/delete-draft`, //POST
    rollback: (practiceId: number,type: 'scr-sf' | 'prp-sf' | 'rel' | string) => `${environment.apiUrl}/pratiche/${practiceId}/${type}/rollback`, //POST
    users: {
      set: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/set-users`,
    },
    files: {
      list: (practiceId: number, codTipo: string) => `${environment.apiUrl}/pratiche/${practiceId}/files/${codTipo}`,
      store: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file`,
      update: (practiceId: number, fileId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file/${fileId}`,
      delete: (practiceId: number, fileId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file/${fileId}`,
      download: (practiceId: number, fileId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file/${fileId}`,
    },
    rse: {
      store: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file-rse`,
      update: (practiceId: number, fileId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file-rse/${fileId}`,
      delete: (practiceId: number, fileId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file-rse/${fileId}`,
    },
    crnt: {
      store: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file-crn-t`,
      update: (practiceId: number, fileId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file-crn-t/${fileId}`,
      delete: (practiceId: number, fileId: number) => `${environment.apiUrl}/pratiche/${practiceId}/file-crn-t/${fileId}`,
    },
    note: {
      store: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/note`,
      update: (practiceId: number, idNota: number) => `${environment.apiUrl}/pratiche/${practiceId}/note/${idNota}`,
      delete: (practiceId: number, idNota: number) => `${environment.apiUrl}/pratiche/${practiceId}/note/${idNota}`,
    },
    costi: {
      getPreventivo: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/costs-and-billing`, //get
      setPreventivo: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/costs-and-billing`, //get
    },
    fascicolo: {
      getDocumentPreset: (type: string) => `${environment.apiUrl}/pratiche/documents/${type}/presets`, //get
      downloadPdf: (practiceId: number, type: string) => `${environment.apiUrl}/pratiche/${practiceId}/document/${type}`, //get
      duplicateToCTU: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/generic-2-ctu`, //post
      updateSettings: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/settings`, //put
      preperizia: {
        getStudioFattibilita: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/prp-sf`, //get
        setStudioFattibilita: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/prp-sf`, //post
        changeStatusStudioFattibilita: (practiceId: number, target: string | null) => `${environment.apiUrl}/pratiche/${practiceId}/prp-sf/manage-pending${target ? ('/' + target) : ''}`, //get
        switchLockStudioFattibilita: (practiceId: number, role: string) => `${environment.apiUrl}/pratiche/${practiceId}/prp-sf/switch-lock/${role}`, //post
        duplicatePraticaPRP: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/prp-2-pr`, //post
        evaluate: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/prp-sf/evaluate`, //post
      },
      parereSpecialistico: {
        suggestMls: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/suggest-mls`,
        setMls: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/set-mls-req-ps`,
        getParereSpecialistico: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/ps`, //get
        setParereSpecialistico: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/ps`, //post
      },
      relazione: {
        getRelazione: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/rel`, //get
        setRelazione: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/rel`, //post
        switchLockRelazione: (practiceId: number, role: string) => `${environment.apiUrl}/pratiche/${practiceId}/rel/switch-lock/${role}`, //post
        evaluate: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/rel/evaluate`, //post
      },
      preventivo: {
        getPreventivo: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/qg-pr`, //get
        setPreventivo: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/qg-pr`, //post
        switchLockPreventivo: (practiceId: number, role: string) => `${environment.apiUrl}/pratiche/${practiceId}/qg-pr/switch-lock/${role}`, //post
        evaluate: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/qg-pr/evaluate`, //post
      },
      screening: {
        getStudioFattibilita: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/scr-sf`, //get
        setStudioFattibilita: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/scr-sf`, //post
        changeStatusStudioFattibilita: (practiceId: number, target: string | null) => `${environment.apiUrl}/pratiche/${practiceId}/scr-sf/manage-pending${target ? ('/' + target) : ''}`, //get
        switchLockStudioFattibilita: (practiceId: number, role: string) => `${environment.apiUrl}/pratiche/${practiceId}/scr-sf/switch-lock/${role}`, //post
        duplicatePraticaSCR: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/scr-2-prp`, //post
        evaluate: (practiceId: number) => `${environment.apiUrl}/pratiche/${practiceId}/scr-sf/evaluate`, //post
      }
    }

  }
}
