import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
  selector: 'app-form-array-generator',
  templateUrl: './form-array-generator.component.html',
  styleUrls: ['./form-array-generator.component.scss']
})
export class FormArrayGeneratorComponent implements OnInit, OnChanges {

  editorConfig: AngularEditorConfig = {
    editable: true,
    minHeight: '15rem',
    placeholder: this.translate.instant('shared.form-array.testo.placeholder'),
    translate: 'no',
    sanitize: true,
    toolbarHiddenButtons: [
      [
        'heading',
        'textColor',
        'fontSize',
        'backgroundColor',
        'link',
        'unlink',
        'insertVideo',
        'subscript',
        'superscript',
        'strikeThrough',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertHorizontalRule',
        'toggleEditorMode',
        'insertClearFormatting',
        'insertUnorderedList',
        'insertOrderedList',
        'fontName',
        'removeFormat',
        // 'insertImage'
      ],
    ],
  };

  @Input() parentForm: FormGroup;
  @Input() initialValue: { title: string, text: string }[] | undefined;
  @Input() canManage: boolean = true;

  constructor(
    private fb: FormBuilder,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {
      console.log(this.initialValue)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initialValue = changes.initialValue.currentValue;
    if (this.initialValue) {
      this.initialValue.forEach((value: { text: string, title: string, additional?: boolean, unique?: boolean }) => {
        this.addSection(value.title, value.text, value.additional, value.unique)
      })
    }
  }

  createSection(title?: string | null, text?: string | null, additional?: boolean, unique?: boolean): FormGroup {
    return this.fb.group({
      title: [{value: title, disabled: !this.canManage || (additional !== true && !unique)}, (unique || (!unique && !additional)) ? Validators.required : ''],
      text: [{value: text, disabled: !this.canManage}, (unique || (!unique && !additional)) ? Validators.required : ''],
      additional: additional,
      unique: unique,
    })
  }

  setValidators(additional?: boolean, unique?: boolean) {
    if (additional && unique) {
      alert('ERRORE')
    } else {
      if (unique) {
        return true
      } else if (additional){
          return true;
        } else {
          return false;
        }
      }
  }

  get formSection(): FormArray {
    return <FormArray>this.parentForm.get('sections');
  }

  addSection(title?: string | null, text?: string | null, additional?: boolean, unique?: boolean) {
    this.formSection.push(this.createSection(title, text, additional, unique));
  }

  deleteSection(id: number) {
    this.formSection.removeAt(id);
  }

  submit() {
    console.log(this.parentForm);
  }

}
